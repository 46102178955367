import { useCallback } from "react";

import { Button, Tooltip } from "@hexocean/braintrust-ui-components";
import { useLazyInitCoinbasePaymentQuery } from "@js/apps/on-ramp/api/";
import {
  CreatingWalletModalInstance,
  openCreatingWalletModal,
} from "@js/apps/on-ramp/components/creating-wallet-modal";
import { openSimpleOnrampModal } from "@js/apps/on-ramp/components/onramp-modal/simple-onramp-flow";
import { useCheckCreatingWalletStatus } from "@js/apps/on-ramp/hooks/use-check-creating-wallet-status";
import {
  setIsCreatingWalletModalOpen,
  setIsPollingForWalletStatusEnabled,
} from "@js/apps/on-ramp/reducer";
import { useAppDispatch } from "@js/hooks";

export const BuyBTRSTButton = () => {
  const [initPayment] = useLazyInitCoinbasePaymentQuery();

  const { isPollingForWalletStatusEnabled } = useCheckCreatingWalletStatus({
    onWalletCreated: () => {
      openSimpleOnrampModal();
    },
  });

  const dispatch = useAppDispatch();

  const handleOnrampInitialization = useCallback(async () => {
    if (isPollingForWalletStatusEnabled) {
      return;
    }

    try {
      const result = await initPayment().unwrap();

      const isWalletReady = result && !result.wallet_creation_in_progress;

      if (isWalletReady) {
        openSimpleOnrampModal();

        return;
      }

      openCreatingWalletModal({
        onOpen: () => {
          dispatch(setIsCreatingWalletModalOpen(true));
        },
        onClose: () => {
          dispatch(setIsCreatingWalletModalOpen(false));
        },
      });

      dispatch(setIsPollingForWalletStatusEnabled(true));
    } catch (err) {
      console.error("Error initializing onramp: ", err);
    }
  }, [dispatch, initPayment, isPollingForWalletStatusEnabled]);

  return (
    <>
      <Tooltip
        disabled={!isPollingForWalletStatusEnabled}
        title="Your wallet is being created. We will notify you once it is done."
      >
        <span>
          <Button
            onClick={handleOnrampInitialization}
            size="medium"
            variant="white-grey"
            shape="squared"
            disabled={isPollingForWalletStatusEnabled}
          >
            Buy
          </Button>
        </span>
      </Tooltip>
      <CreatingWalletModalInstance />
    </>
  );
};
