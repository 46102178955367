import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";
import { RouterLink } from "@js/components/link";

import { HelpOfferButtonPrimary } from "./help-offer-button-primary";

export const HelpOfferButtonsCompleted = ({
  offer,
  isMobile,
  isOfferAuthor,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const isOfferAuthorRemoved = offer.author.user.is_removed;

  if (
    offer.review_completed ||
    offer.reported ||
    isOfferAuthor ||
    isOfferAuthorRemoved
  )
    return null;

  return (
    <>
      <HelpOfferButtonPrimary
        RouterLink={RouterLink}
        to={`${offer.author.user.profile_url}?write_review=true&offer=${offer.id}&content_type=${ENUMS.ReviewType.HELP_OFFER_REVIEW}`}
      >
        {`Leave ${!isMobile ? offer.author.user.first_name : ""} a review`}
      </HelpOfferButtonPrimary>
    </>
  );
};
