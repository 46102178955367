import { API } from "@js/api";

export type InitCoinbasePaymentResponseWithExistingWallet = {
  wallet_creation_in_progress: false;
  safe_wallet_address: string;
};

type InitCoinbasePaymentResponseWithoutExistingWallet = {
  wallet_creation_in_progress: true;
  safe_wallet_address: null;
};

export type InitCoinbasePaymentResponse =
  | InitCoinbasePaymentResponseWithExistingWallet
  | InitCoinbasePaymentResponseWithoutExistingWallet;

export type OnRampSessionTokenResponse = {
  token: string;
};

const coinbaseApi = API.injectEndpoints({
  endpoints: (build) => ({
    initCoinbasePayment: build.query<
      | InitCoinbasePaymentResponseWithExistingWallet
      | InitCoinbasePaymentResponseWithoutExistingWallet,
      void
    >({
      query: () => ({
        url: `/onramp_initial_payment_info/`,
        method: "GET",
      }),
    }),
    getOnRampSessionToken: build.query<OnRampSessionTokenResponse, void>({
      query: () => ({
        url: `/onramp/generate_session_token/`,
        method: "GET",
      }),

      keepUnusedDataFor: 0,
    }),
    createOnrampTransaction: build.mutation<void, { uuid: string }>({
      query: (data) => ({
        url: `/onramp/create_coinbase_transaction/`,
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useLazyInitCoinbasePaymentQuery,
  useInitCoinbasePaymentQuery,
  useLazyGetOnRampSessionTokenQuery,
  useGetOnRampSessionTokenQuery,
  useCreateOnrampTransactionMutation,
} = coinbaseApi;
