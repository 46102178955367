import React, { useMemo, useRef } from "react";
import { Field, SubmissionError } from "redux-form";

import { Box, IconButton } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { EditPenIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useIsProfileOwnerContext } from "@js/apps/common/profile/is-profile-owner-context";
import { ImageUploadField } from "@js/apps/file-upload";
import { useUpdateFreelancerCustomizationMutation } from "@js/apps/freelancer/api";
import { createFormInstance } from "@js/forms/components";
import type {
  FreelancerForNodeStaff,
  FreelancerPublic,
} from "@js/types/freelancer";

import styles from "./styles.module.scss";

type CoverImageFormData = {
  cover_photo_id: number;
};

const CoverImageFormInstance = createFormInstance<CoverImageFormData, unknown>(
  "cover-image-form",
  {
    onChange: (values, _dispatch, props, previousValues) => {
      if (
        typeof values.cover_photo_id !== "undefined" &&
        previousValues.cover_photo_id !== values.cover_photo_id
      ) {
        props.submit?.();
      }
    },
  },
);

type ProfnetProfilePageCoverImageProps = {
  profile: FreelancerPublic | FreelancerForNodeStaff;
};

export const ProfnetProfilePageCoverImage: React.FC<
  ProfnetProfilePageCoverImageProps
> = ({ profile }) => {
  const customDropzoneRef = useRef<{ open: () => void }>();
  const { isProfileOwner } = useIsProfileOwnerContext();

  const isMobileOrTablet = useMediaQuery("md");

  const [updateFreelancerCustomization] =
    useUpdateFreelancerCustomizationMutation();

  const handleClick = () => {
    customDropzoneRef?.current?.open?.();
  };
  const existingImages = useMemo(() => {
    if (!profile.cover_photo_id || !profile.cover_photo) {
      return;
    }

    return [
      {
        id: profile.cover_photo_id,
        attachment: {
          file: profile.cover_photo,
          name: String(profile.cover_photo_id),
        },
      },
    ];
  }, [profile.cover_photo_id, profile.cover_photo]);

  return (
    <Box className={styles.container}>
      {isProfileOwner ? (
        <CoverImageFormInstance
          onSubmit={(data: CoverImageFormData) => {
            const isInvalidData = !data || Object.keys(data).length === 0;
            if (isInvalidData) {
              return;
            }

            return updateFreelancerCustomization({
              id: profile.id,
              data: { user: data },
            })
              .unwrap()
              .catch((error) => {
                throw new SubmissionError(error.data);
              });
          }}
          hideError
          className={styles.form}
        >
          <Field
            name="cover_photo_id"
            component={ImageUploadField}
            subtitle={
              !isMobileOrTablet ? "Recommended image size: 1200 x 148 px" : ""
            }
            uploadType={ENUMS.UploadType.FREELANCER_COVER_PHOTO_IMAGE_UPLOAD}
            fileDropzonePlaceholderProps={{
              className: styles.placeholderContainer,
            }}
            fileDropzoneProps={{
              tabIndex: -1, // focus is on the button
              customRef: customDropzoneRef,
            }}
            existingImages={existingImages}
            displayedImage={
              profile.cover_photo ||
              `${SETTINGS.STATIC_URL}freelancer/head-banner.svg`
            }
          />
          <IconButton
            className={styles.uploadButton}
            variant="black-outlined"
            size="x-small"
            onClick={handleClick}
            aria-label="Edit cover image"
          >
            <EditPenIcon />
          </IconButton>
        </CoverImageFormInstance>
      ) : (
        <img
          src={
            profile.cover_photo ||
            `${SETTINGS.STATIC_URL}freelancer/head-banner.svg`
          }
          alt="user cover"
          className={styles.coverImage}
        />
      )}
    </Box>
  );
};
