/**
 * Throw this error if the function should retry
 */
export class RetryableError extends Error {
  constructor(msg) {
    super(msg);

    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // https://github.com/microsoft/TypeScript/issues/13965
    Object.setPrototypeOf(this, RetryableError.prototype);
  }
}
