import {
  Box,
  IconButton,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Logo } from "@js/components/logo";

type BalanceCardProps = {
  title: string;
  count: number;
  variant?: "default" | "withLogo";
  tooltip: string;
};

export const BalanceCard: React.FC<BalanceCardProps> = ({
  title,
  count,
  tooltip,
  variant = "default",
  ...props
}) => {
  const isBalanceCardWithLogo = variant === "withLogo";

  return (
    <Box className={`balance-card balance-card--${variant}`} {...props}>
      <Box className="balance-card__title">
        <Typography component="p" size="small" variant="paragraph">
          {title}
        </Typography>
        <Tooltip
          title={
            <Typography component="p" size="small" variant="paragraph">
              {tooltip}
            </Typography>
          }
        >
          <span>
            <IconButton
              aria-label="tokens balance"
              variant="primary"
              size="x-small"
              style={{ pointerEvents: "none" }}
            >
              <InfoIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box className="balance-card__count">
        {isBalanceCardWithLogo && (
          <Logo
            variant="symbol-white"
            size={40}
            alt="Braintrust"
            className="balance-card__logo"
          />
        )}
        <Typography
          component="p"
          fontWeight={400}
          size={isBalanceCardWithLogo ? "large" : "small"}
          variant="title"
        >
          {count}
        </Typography>
        <Typography component="p" size="small" variant="paragraph" ml={1}>
          BTRST
        </Typography>
      </Box>
    </Box>
  );
};
