import { useParams } from "react-router-dom";

import { Button, Loader, Typography } from "@hexocean/braintrust-ui-components";
import {
  useGetNftDetailsQuery,
  useGetNftStatusQuery,
  useGetNftTokenMetaQuery,
} from "@js/apps/nft/api";
import { openShareModal } from "@js/apps/nft/components";
import {
  getNftAttribute,
  getProfileDescriptionMessage,
} from "@js/apps/nft/utils";
import { ButtonCopyToClipboard } from "@js/components/buttons/";
import { RouterLink } from "@js/components/link";
import { PageHead } from "@js/components/page-head";

import { Video } from "../../components/nft-video-img";

import { BalanceCard } from "./balance-card";
import { PublicOnChainProfile } from "./public-on-chain-profile";

export const NFTManagementPage = () => {
  const { tokenId } = useParams();
  const _tokenId = Number(tokenId);
  const { isLoading, data: nftMeta } = useGetNftTokenMetaQuery(_tokenId);
  const { data: nftStatus } = useGetNftStatusQuery();
  const { data: nftDetails } = useGetNftDetailsQuery(_tokenId);
  const isPublicView = _tokenId !== nftStatus?.nft_token_id;
  const membershipTier = getNftAttribute(
    nftMeta?.attributes,
    ENUMS.NFTMetadataTraitTypeLabels.membership_tier,
  );

  if (isLoading || !nftMeta || !nftDetails) {
    return <Loader centered color="info" />;
  }

  return (
    <>
      <PageHead>
        <meta property="og:title" content={nftMeta.name} />
        <meta property="og:image" content={nftMeta.image} />
        <meta
          property="og:description"
          content={getProfileDescriptionMessage(_tokenId)}
        />
        <meta
          name="description"
          content={getProfileDescriptionMessage(_tokenId)}
        />
        <meta property="og:url" content={window.location.href} />
      </PageHead>
      <div className="nft-management-page">
        <div className="nft-management-page__wrapper">
          <div className="nft-management-page__head">
            <div className="nft-management-page__tier">
              Membership Tier {membershipTier.value}
            </div>
            <Typography
              className="nft-management-page__user-name"
              component="h1"
              fontWeight={400}
              variant="title"
              size="small"
            >
              {nftMeta.name}
            </Typography>
            <BalanceCard
              title="Available balance"
              count={nftDetails.balances.available_balance || 0}
              variant="withLogo"
              tooltip="The amount of tokens deposited in this NFT, minus the amount of Locked Tokens."
            />
            <div className="nft-management-page__balance-cards">
              <BalanceCard
                title="Total balance"
                count={nftDetails.balances.total_balance || 0}
                tooltip="The total amount of tokens deposited in the NFT, including Locked Tokens."
              />
              <BalanceCard
                title="Locked tokens"
                count={nftDetails.balances.locked_tokens || 0}
                tooltip="The amount of tokens gifted to this NFT that are subject to a two year lockup."
              />
            </div>
            {!isPublicView && (
              <div className="nft-management-page__deposit">
                <Button size="medium" variant="white-grey" disabled>
                  Deposit BTRST
                </Button>
                <Button size="medium" variant="white-grey" disabled>
                  Withdraw BTRST
                </Button>
                <Typography
                  component="p"
                  size="small"
                  color="white"
                  variant="paragraph"
                >
                  Coming soon!
                </Typography>
              </div>
            )}
            <div className="nft-management-page__share">
              <Button
                variant="tertiary"
                className="share-button"
                onClick={openShareModal}
              >
                Share NFT
              </Button>
              <ButtonCopyToClipboard
                className="share-link"
                variant="tertiary"
                successTextColor="white"
                text={window.location.href}
              >
                Copy link
              </ButtonCopyToClipboard>
              <Button
                variant="tertiary"
                className="share-link"
                target="_blank"
                href={nftDetails.nft_etherscan_url}
                RouterLink={RouterLink}
              >
                View on Etherscan
              </Button>
            </div>
          </div>
          <Video
            src={`${SETTINGS.STATIC_URL}nft/nft.mp4`}
            className="nft-management-page__image"
          />
        </div>
        <PublicOnChainProfile profile={nftMeta} tokenId={_tokenId} />
      </div>
    </>
  );
};
