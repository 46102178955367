/**
 * This error is thrown if the function is cancelled before completing
 */
export class CancelledError extends Error {
  constructor() {
    super("Cancelled");

    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // https://github.com/microsoft/TypeScript/issues/13965
    Object.setPrototypeOf(this, CancelledError.prototype);
  }
}
